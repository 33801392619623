import Gallery from '@browniebroke/gatsby-image-gallery'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import * as styles from '../../assets/css/civic.module.css'
import Layout from '../../components/Layout'
import SmartLink from '../../components/SmartLink'

const essayQuery = graphql`
  query {
    content(uid: { eq: "c10" }) {
      headline
      items {
        content
        images {
          childImageSharp {
            full: gatsbyImageData(layout: FULL_WIDTH)
            thumb: gatsbyImageData(height: 150, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

const CivicInstitutions = () => {
  const { content } = useStaticQuery(essayQuery)

  const images = []
  content['items'].forEach((element) => {
    const imageData = element['images'][0].childImageSharp
    imageData.thumbAlt = element['content']
    imageData.title = element['content']
    images.push(imageData)
  })

  return (
    <Layout>
      <section>
        <h1>Civic Institutions</h1>
        <section>
          <div>
            <div></div>
            <div>
              <p>
                By Diane Dorney
                <br />
                Executive Director, The Seaside Institute
              </p>
              <blockquote>
                <em>
                  Premises used by organizations considered to support the
                  common good and, therefore, accorded special treatment within
                  the [town]. Civic uses include educational, cultural, social,
                  service, and religious not-for-profit organizations. Existing
                  and potential civic organizations should have sites reserved
                  within every traditional neighborhood development even if
                  their advent is in the distant future. Communities develop
                  such institutions with the reserved civic sites acting as a
                  reminder and an incentive.
                </em>
              </blockquote>
              <div style={{ textAlign: 'right' }}>
                <em>
                  "The Lexicon of the New Urbanism,"
                  <br />
                  Duany Plater-Zyberk & Company
                </em>
              </div>
              <p>
                For hundreds of years, civic institutions and the spaces around
                them were the most cherished and vital places in cities and
                towns. Considered the cornerstone of a culture and society, the
                churches, town hall, schools and arts venues were given the most
                honorable and accessible locations. This came to an end with the
                advent of Euclidean zoning, when civic institutions became
                isolated, stand-alone structures surrounded by a sea of parking
                in hard to reach places accessible only by car.
              </p>
              <p>
                The innovative town plan for Seaside calls for a return to the
                former practice of placing its civic sites in the middle of the
                town. Reserved and restricted for civic use only, some of these
                sites have yet to be built out completely; however, a solid
                inventory of civic buildings is already in place for this
                relatively young town. These institutions include a post office,
                school, chapel and building for the town’s nonprofit
                organizations.
              </p>
              <p>
                Several of Seaside’s civic buildings are located in the town
                Lyceum, a large horseshoe-shaped space, which is only partially
                completed. Intended as a center for learning and the arts, the
                Lyceum is modeled after Thomas Jefferson’s plan for the
                University of Virginia and links various schools, lecture halls
                and meeting rooms around a green.
              </p>
              <p>
                The Seaside Neighborhood School jump-started the Lyceum plan and
                now occupies three large buildings there. In 2012, the Seaside
                Institute designed and built an Academic Village, which was
                placed on the southwest side of the Lyceum green. Here, 7 small
                cottages surround a large courtyard where students, faculty,
                artists and seminar attendees can stay and gather while studying
                and working in Seaside. Adjacent to the Academic Village, a
                two-story Assembly Hall was built to provide classroom and
                lecture spaces.
              </p>
              <h2>Civic Institutions in Seaside</h2>
              <div className={styles.container}>
                <div className={styles.image}>
                  <Gallery
                    colWidth='100'
                    mdColWidth='100'
                    gutter='0'
                    rowMargin='0'
                    images={[content.items[0].images[0].childImageSharp]}
                  />
                </div>
                <div className={styles.info}>
                  <SmartLink to='/structures/s34/'>
                    <h2>Seaside Post Office</h2>
                  </SmartLink>
                </div>
                <div className={styles.image}>
                  <Gallery
                    colWidth='100'
                    mdColWidth='100'
                    gutter='0'
                    rowMargin='0'
                    images={[content.items[1].images[0].childImageSharp]}
                  />
                </div>
                <div className={styles.info}>
                  <SmartLink to='/structures/s236/'>
                    <h2>Seaside Chapel</h2>
                  </SmartLink>
                  <div className={styles.table}>
                    <div className={styles.bold}>Alternate Name:</div>{' '}
                    <div>Seaside Interfaith Chapel</div>
                    <div className={styles.bold}>Architect:</div>{' '}
                    <div>Scott Merrill</div>
                    <div className={styles.bold}>Construction:</div>{' '}
                    <div>1999–2001</div>
                    <div className={styles.bold}>Code Type:</div>{' '}
                    <div>Type V — Special District</div>
                    <div className={styles.bold}>Building Type:</div>{' '}
                    <div>Civic</div>
                  </div>
                </div>
                <div className={styles.image}>
                  <Gallery
                    colWidth='100'
                    mdColWidth='100'
                    gutter='0'
                    rowMargin='0'
                    images={[content.items[2].images[0].childImageSharp]}
                  />
                </div>
                <div className={styles.info}>
                  <SmartLink to='/structures/s393/'>
                    <h2>Seaside Neighborhood School</h2>
                  </SmartLink>
                  <div className={styles.table}>
                    <div className={styles.bold}>Alternate Name:</div>{' '}
                    <div>Seaside School</div>
                    <div className={styles.bold}>Architect:</div>{' '}
                    <div>Richard Gibbs</div>
                    <div className={styles.bold}>Construction:</div>{' '}
                    <div>1996</div>
                    <div className={styles.bold}>Code Type:</div>{' '}
                    <div>Type V — Special District</div>
                    <div className={styles.bold}>Building Type:</div>{' '}
                    <div>Civic</div>
                  </div>
                </div>
                <div className={styles.image}>
                  <Gallery
                    colWidth='100'
                    mdColWidth='100'
                    gutter='0'
                    rowMargin='0'
                    images={[content.items[3].images[0].childImageSharp]}
                  />
                </div>
                <div className={styles.info}>
                  <h2>Leon Krier Amphitheater Plaza and Stage</h2>
                </div>
                <div className={styles.image}>
                  <Gallery
                    colWidth='100'
                    mdColWidth='100'
                    gutter='0'
                    rowMargin='0'
                    images={[content.items[4].images[0].childImageSharp]}
                  />
                </div>
                <div className={styles.info}>
                  <SmartLink to='/structures/s408/'>
                    <h2>The Academic Village</h2>
                  </SmartLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}

export default CivicInstitutions
